<template>
  <div>
    <div class="main_center" style="width: 900px">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules1" :inline="true">

        <!-- <NewFileUploadModule ref="NewFileUploadModule_1" :dcConfig="dcConfig" :fileLimit="3" :fileMaxSize="300"
          :fileType='[".jpg", ".png", ".gif", ".jpeg", ".webp", ".bmp",".mp4"]' @jsyPending="jsyPending" @jsyResolve="jsyResolve"
          :isShowProgressBar="true">
          <el-button type="primary">选择文件</el-button>
        </NewFileUploadModule> -->

        <el-form-item label="上传文件：" prop="resource_id" class="div_row_box">
          <div class="upload_div_box">
            <!-- <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi99"
              :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false"
              v-if="dcConfig.key != '' && dcConfig.notify_url != ''"
              :accept='addType == 1 ? ".mp4" : ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.flv,.mp4,.mpg,.gif,.jpg,.jpeg,.png"'>
              <el-button type="primary">选择文件</el-button>
            </dc-upload> -->
            <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi99"
              :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false"
              v-if="dcConfig.key != '' && dcConfig.notify_url != ''"
              accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.flv,.mp4,.mpg,.gif,.jpg,.jpeg,.png">
              <el-button type="primary">选择文件</el-button>
            </dc-upload>
            <p>{{ form.name }}</p>
          </div>
        </el-form-item>
        <el-form-item label="资源名称：" prop="name" class="div_row_box">
          <el-input placeholder="默认为文件名" class="two" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="资源类型：" prop="resourcetypesdata">
          <el-select v-model="form.resourcetypesdata" placeholder="请选择资源类型" class="two">
            <el-option v-for="(item, index) in resourcetypesOption" :key="index" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="folderFiles" label="所属文件夹：" prop="type" class="div_row_box">
          <el-select v-model="form.type" placeholder="请选择文件夹" class="two">
            <el-option v-for="item in optiones1" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="three_select div_row_box">
          <el-form-item label="学段学科：">
            <el-select v-model="form.stagedata" placeholder="请选择学段" class="three" @change="handleStageChange"
              style="margin-right: 8px">
              <el-option v-for="(item, index) in stageOption" :key="index" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select placeholder="请选择学科" class="three" v-model="form.subjectdata" @change="handleSubjectChange"
              style="margin-right: 8px">
              <el-option v-for="(item, index) in subjectOption" :key="index" :label="item.subjectName"
                :value="item.subjectId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="form.gradedata" placeholder="请选择年级" class="three" style="margin-right: 8px"
              @change="handleGradeChange">
              <el-option v-for="(item, index) in gradeOption" :key="index" :label="item.gradeName"
                :value="item.gradeId"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>

      <div class="five"></div>
      <div class="six">
        <p>注意事项：</p>
        <!-- <p v-if="addType == 1">
          1.文件支持格式：mp4格式
        </p>
        <p v-else>
          1.文件支持格式：
          doc、docx、xls、xlsx、ppt、pptx、pdf、txt、flv、mp4、mpg、gif、jpg、jpeg、png
        </p> -->
        <p>
          1.文件支持格式：
          doc、docx、xls、xlsx、ppt、pptx、pdf、txt、flv、mp4、mpg、gif、jpg、jpeg、png
        </p>
        <p>2.单个文件大小：≤1G </p>
      </div>
      <div class="save">
        <div class="save_one cursor" @click="onePartCancel">取消</div>
        <el-button class="save_two cursor" type="primary" @click="twopartSave" :loading="save_loading_btn">保存
          <span class="temp_class_span_div">-89t</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import NewFileUploadModule from "@/components/uploadResource/NewFileUploadModule/NewFileUploadModule.vue";
import { debounce, throttle } from "@/utils/utils.js";
export default {
  components: {
    NewFileUploadModule,
  },
  props: {
    // 是否要文件夹
    folderFiles: {
      type: Boolean,
      default: false,
    },
    // true:返回上一页，false：关闭弹窗
    flag: {
      type: Boolean,
      default: true,
    },
    // 1四名工作室上传资源，2个人中心上传资源，3活动模块上传资源
    is_who: {
      type: Number,
      default: 1,
    },
    //活动模块  1教研活动  2是四名工作室
    is_from: {
      type: String,
      default: "1",
    },

    activity_id: {
      type: Number,
      default: 0,
    },
    link_id: {
      type: Number,
      default: 0,
    },
    addType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      save_loading_btn: false,
      fileList: [
        { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
        { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
      ],
      rules1: {
        resource_id: [
          { required: true, message: "请上传文件", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入文件名", trigger: "blur" }],
        resourcetypesdata: [
          { required: true, message: "请选择资源类型", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择文件夹", trigger: "change" }],
        stagedata: [
          { required: true, message: "请选择学段", trigger: "change" },
        ],
        subjectdata: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        gradedata: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
      },
      // 上传资源表单
      form: {
        name: "",
        resource_id: "",
        stagedata: "",
        subjectdata: "",
        gradedata: "",
        resourcetypesdata: "",
        versiondata: "",
        chapterdata: "",
        areadata: "",
        yeardata: "",
        url: "",
        type: "",
      },
      //学段数据选项
      stageOption: [],
      //学科数据选项
      subjectOption: [],
      // 年级数据选项
      gradeOption: [],
      //资源类型数据选项
      resourcetypesOption: [],
      // 版本列表数据选项
      versionOption: [],
      // 章节列表数据选项
      chapterOption: [],
      // 地区数据选项
      areaOption: [],
      // 年份数据选项
      yearOption: [],
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      // 文件夹
      optiones1: [],
      isOk: false,
    };
  },
  methods: {
    // 金山云 上传进度
    jsyPending(e) {
      console.log(e, "【jsyPending】");
    },
    // 金山云 上传完成
    jsyResolve(e) {
      console.log(e, "【jsyResolve】");
    },
    // 金山云 取消上传
    handleCancel(e) {
      if (this.$refs['NewFileUploadModule_1']) {
        this.$refs['NewFileUploadModule_1'].jsyCancel();
      };
      console.log("【 取消上传 】");
    },
    //初始化文件夹数据
    initFile() {
      if (this.folderFiles) {
        let form1 = {
          page: "",
          limit: "",
          search_name: "",
          teaching_studio_id: localStorage.getItem("studioId"),
        };
        this.$axios
          .get("TeachingStudioUserResourceType/list", { params: form1 })
          .then((res) => {
            let temArr = res.data.data.data;
            let obj = { id: 1, name: "默认文件夹" };
            temArr.unshift(obj);
            this.optiones1 = temArr;
            console.log(
              JSON.parse(JSON.stringify(temArr)),
              "【 temArr 文件夹 】"
            );
          });
      }
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      var resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");

      this.form.name = data.filename;
      this.form.url = resData.Location;
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });
      this.form.resource_id = res.data.data.id;
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isOk = true;
    },
    // 请求后台获取学段数据
    getStage() {
      this.$axios.get("stage/stage_list").then((res) => {
        this.stageOption = res.data.data;
      });
    },
    // 请求后台获取学科数据
    getSubject() {
      this.$axios
        .get("subject/list", {
          params: {
            pharseid: this.form.stagedata,
          },
        })
        .then((res) => {
          var resdata = res.data;
          this.subjectOption = resdata.data;
        });
    },
    // 学段改变时触发的函数
    handleStageChange() {
      this.form.subjectdata = "";
      this.form.gradedata = "";
      this.form.versiondata = "";
      this.form.chapterdata = "";
      this.form.areadata = "";
      this.form.yeardata = "";
      this.getSubject();
    },
    // 请求后台获取年级数据
    getGrade() {
      var obj = {
        params: {
          pharseid: this.form.stagedata,
          subjectId: this.form.subjectdata,
        },
      };
      this.$axios.get("grade/list", obj).then((res) => {
        var resdata = res.data;
        this.gradeOption = resdata.data;
      });
    },
    //学科改变时触发的函数
    handleSubjectChange() {
      this.form.gradedata = "";
      this.form.versiondata = "";
      this.form.chapterdata = "";
      this.form.areadata = "";
      this.form.yeardata = "";
      this.getGrade();
      this.getArea();
      this.getYear();
    },
    // 请求后台获取资源类型
    getresourceTypes() {
      let params = {
        resource_type: this.addType
      }
      this.$axios.get("/resourceTypes", { params }).then((res) => {
        this.resourcetypesOption = res.data.data;
      });
    },
    // 请求后台获取版本
    getVersion() {
      var obj = {
        params: {
          pharseid: this.form.stagedata,
          subjectId: this.form.subjectdata,
          gradeId: this.form.gradedata,
        },
      };
      this.$axios.get("versions/list", obj).then((res) => {
        var resdata = res.data;
        this.versionOption = resdata.data;
      });
    },
    // 年级改变触发的函数
    handleGradeChange() {
      this.form.versiondata = "";
      this.form.chapterdata = "";
      this.getVersion();
    },
    // 请求后台获取章节
    getChapter() {
      var obj = {
        params: {
          pharseid: this.form.stagedata,
          subjectid: this.form.subjectdata,
          gradeid: this.form.gradedata,
          editionid: this.form.versiondata,
        },
      };
      this.$axios.get("chapter/list", obj).then((res) => {
        var resdata = res.data;
        this.chapterOption = resdata.data;
      });
    },
    // 版本改变触发函数
    handleVersionChange() {
      this.form.chapterdata = "";
      this.getChapter();
    },
    // 请求后台获取地区
    getArea() {
      var pharse = "";
      this.stageOption.forEach((item) => {
        if (item.id == this.form.stagedata) {
          pharse = item.name;
        }
      });
      var subject = "";
      this.subjectOption.forEach((item) => {
        if (item.subjectId == this.form.subjectdata) {
          subject = item.subjectName;
        }
      });
      var obj = {
        params: {
          pharse: pharse,
          subject: subject,
        },
      };
      this.$axios.get("resourceArea", obj).then((res) => {
        var resdata = res.data;
        this.areaOption = resdata.data;
      });
    },
    // 请求后台获取年份
    getYear() {
      var pharse = "";
      this.stageOption.forEach((item) => {
        if (item.id == this.form.stagedata) {
          pharse = item.name;
        }
      });
      var subject = "";
      this.subjectOption.forEach((item) => {
        if (item.subjectId == this.form.subjectdata) {
          subject = item.subjectName;
        }
      });
      var obj = {
        params: {
          pharse: pharse,
          subject: subject,
        },
      };
      this.$axios.get("resourceYear", obj).then((res) => {
        var resdata = res.data;
        let array = [];
        for (let i = 0; i < resdata.data.length; i++) {
          if (
            resdata.data[i].year != "不限" &&
            resdata.data[i].year != "更早"
          ) {
            array.push(resdata.data[i]);
          }
        }
        this.yearOption = array;
      });
    },
    // 取消
    onePartCancel() {
      if (this.flag) {
        this.$router.go(-1);
      } else {
        this.$emit("onChangeStatus");
      }
    },
    // 保存
    twopartSave: throttle(function () {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return false;
        }
        let obj = {
          // resource_id: this.form.resource_id,
          // titles: this.form.name,
          sources: this.form.resourcetypesdata,
          grade: this.form.gradedata.toString(),
          edition: this.form.versiondata.toString(),
          subject: this.form.subjectdata.toString(),
          year: this.form.yeardata,
          area: this.form.areadata,
          chapter_id: this.form.chapterdata.toString(),
          pharse: this.form.stagedata.toString(),
          type: this.form.type,
          resource_type: this.addType,
          // url: this.form.url,
          // teaching_studio_id: localStorage.getItem("studioId"),
        };
        let p = null;
        if (this.is_who == 1) {
          // 四名工作室
          obj = {
            ...obj,
            resource_id: this.form.resource_id,
            titles: this.form.name,
            url: this.form.url,
            teaching_studio_id: localStorage.getItem("studioId"),
          };
          this.save_loading_btn = true;
          p = this.$axios.post("TeachingStudioUserResource/insertGetIdResource", obj);
        } else if (this.is_who == 2) {
          // 个人中心
          obj = {
            title: this.form.name,
            sources: this.form.resourcetypesdata.toString(),
            grade: this.form.gradedata.toString(),
            subject: this.form.subjectdata.toString(),
            pharse: this.form.stagedata.toString(),
            resource_id: this.form.resource_id,
            url: this.form.url,
          };
          this.save_loading_btn = true;
          p = this.$axios.post("upload/library", obj);
        } else {
          // 活动
          obj = {
            source: this.form.resourcetypesdata,
            grade: this.form.gradedata.toString(),
            edition: this.form.versiondata.toString(),
            subject: this.form.subjectdata.toString(),
            year: this.form.yeardata,
            area: this.form.areadata,
            chapter_id: this.form.chapterdata.toString(),
            pharse: this.form.stagedata.toString(),
            resource_id: this.form.resource_id,
            name: this.form.name,
            activity_id: this.activity_id,
            link_id: this.link_id,
            type: this.form.type,
            teaching_studio_id: localStorage.getItem("studioId"),
          };
          if (this.is_from == 1) {
            //教研组
            this.save_loading_btn = true;
            p = this.$axios.post("ActivityUserResource/insertResource", obj);
          } else {
            //四名工作室
            this.save_loading_btn = true;
            p = this.$axios.post("TeachingStudioActivityUserResource/insertResource", obj
            );
          }
        }
        p.then(() => {
          this.save_loading_btn = false;
          this.$message.success("上传成功");
          //关闭弹窗
          this.onePartCancel();
        }).catch((err) => {
          this.save_loading_btn = false;
        });
      });
    }),
  },
  created() {
    this._getAddress();
    this.getStage();
    this.getresourceTypes();
    this.initFile();
  },
};
</script>

<style lang="less" scoped>
.div_row_box {
  width: 100%;
  display: block;
}

.main_center {
  .upload_div_box {
    /deep/ .el-progress-circle {
      width: 40px !important;
      height: 40px !important;
    }

    /deep/ .el-progress {
      left: 120px;
    }
  }

  .six {
    margin-left: 35px;

    // margin-bottom:50px;
    p:nth-child(2) {
      color: #999999;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    p:nth-child(3) {
      color: #999999;
    }
  }

  .save {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    .save_one {
      width: 148px;
      height: 42px;
      background: #f7f7f7;
      border: 1px solid #ececec;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      margin-right: 22px;
    }

    .save_two {
      border: none;
      width: 148px;
      height: 42px;
      background: #ff8201;
      border-radius: 6px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>